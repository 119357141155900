import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components'

import Layout from '../components/Layout/Layout';
import SEO from '../components/seo';

const NotFound = styled.div`
	height: 90%;
	display: flex;
	flex-flow: column wrap;
	align-items: center;
	justify-content: center;

	h1 {
		font-size: 120px;
		line-height: 100px;
	}

	p {
		font-size: 24px;
		margin-bottom: 56px;
	}
`

const NotFoundPage = () => (
	<div>
		<SEO title="404: Not found" />
			<Layout>
				{{}}
				<NotFound>
					<h1>404</h1>
					<p>Page Not Found</p>
					<Link className="button" to="/">Go Home</Link>
				</NotFound>
				{{}}
			</Layout>
	</div>
);

export default NotFoundPage;
